import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Box, Button, FormHelperText } from "@mui/material";
import { ChangeEventHandler, FC } from "react";
import { Control, useFieldArray, UseFormGetValues, UseFormSetError } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useUploadFileMutation } from "../hooks";
import { FeedbackFormInputs } from "../types";

import { FileField } from "./FileField";

interface FeedbackFilesContainerProps {
  control: Control<FeedbackFormInputs>;
  getValues: UseFormGetValues<FeedbackFormInputs>;
  setError: UseFormSetError<FeedbackFormInputs>;
}

export const FeedbackFilesContainer: FC<FeedbackFilesContainerProps> = ({
  control,
  getValues,
  setError
}) => {
  const { t } = useTranslation();

  const { append, fields: files, remove, update } = useFieldArray({ control, name: "files" });
  const { mutateAsync } = useUploadFileMutation();

  const handleFilesChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const currentFilesNumber = getValues("files").length;
    const targetFiles = e.target.files;

    if (!targetFiles || currentFilesNumber === 5) {
      return;
    }

    const filesToAppend = Array.from(targetFiles).slice(0, 5 - currentFilesNumber);

    filesToAppend.forEach(async (file, index) => {
      const fileIndex = currentFilesNumber + index;

      append({ file, isUploading: true });

      try {
        const response = await mutateAsync(file);

        const errorMessage = response?.data.error?.message;

        if (errorMessage) {
          setError(`files.${fileIndex}`, { message: errorMessage });
        }

        update(fileIndex, {
          file,
          isUploading: false,
          uuid: response?.data.uuid
        });
      } catch (e) {
        setError(`files.${fileIndex}`, { message: t("feedback.errors.unknown_file_error") });

        update(fileIndex, {
          file,
          isUploading: false
        });
      }
    });
  };

  return (
    <>
      <Button
        startIcon={<AttachFileIcon />}
        variant='outlined'
        component='label'
        disabled={files.length >= 5}
        sx={{ mt: 2 }}
      >
        {t("feedback.attach_file")}
        <input type='file' accept='image/*' multiple hidden onChange={handleFilesChange} value='' />
      </Button>

      <FormHelperText>{t("feedback.max_file_size")}</FormHelperText>

      <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mt: 2 }}>
        {files.map(({ id }, index) => (
          <FileField key={id} control={control} index={index} remove={remove} />
        ))}
      </Box>
    </>
  );
};
