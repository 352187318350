import { Dialog, DialogContent, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { CloseDialogButton } from "components/buttons";

interface FeedbackSuccessDialogProps {
  open: boolean;
  onClose: () => void;
}

export const FeedbackSuccessDialog: FC<FeedbackSuccessDialogProps> = ({ onClose, open }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { width: "100%", maxWidth: "600px" } }}>
      <CloseDialogButton onClick={onClose} />
      <DialogContent>
        <Typography variant='h2' component='p' textAlign='center'>
          {t("feedback.success_dialog_content")}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
