import { createContext, ReactNode, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export interface NavigationContextValue {
  previousPathname: string | null;
}

export const NavigationContext = createContext<NavigationContextValue>({ previousPathname: null });

interface NavigationContextProviderProps {
  children?: ReactNode;
}

export const NavigationContextProvider = ({ children }: NavigationContextProviderProps) => {
  const location = useLocation();
  const previousPathname = useRef<string | null>(location.pathname);

  useEffect(() => {
    previousPathname.current = location.pathname;
  }, [location.pathname]);

  return (
    <NavigationContext.Provider value={{ previousPathname: previousPathname.current }}>
      {children}
    </NavigationContext.Provider>
  );
};
