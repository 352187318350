import { useContext } from "react";

import { NavigationContext, NavigationContextValue } from "../context";

export const useNavigationContext = (): NavigationContextValue => {
  const context = useContext(NavigationContext);

  if (!context) {
    throw new Error("useNavigationContext must be used within a NavigationContext");
  }

  return context;
};
