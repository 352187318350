import { Box, Button, Typography, Link as MuiLink, Paper } from "@mui/material";
import { useEffect, type FC, type MouseEventHandler } from "react";
import { ErrorOption, RegisterOptions, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ConfirmPhoneButton } from "components/buttons";
import { PhoneField, PinCodeField } from "components/form";
import { Paths } from "routes/paths";
import { checkConfirmPhoneDisabled } from "services/checkConfirmPhoneDisabled";
import { filterStringNumbers } from "utils/filterStringNumbers";

import { useGetPinCodeMutation, useConfirmPinCodeMutation } from "../../hooks";

import { ServerErrorAlert } from "./ServerErrorAlert";

interface SignInFormInputs {
  phone: string;
  pinCode: string;
}

const defaultValues: SignInFormInputs = {
  phone: "",
  pinCode: ""
};

export const SignInForm: FC = () => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    setFocus,
    trigger,
    watch,
    clearErrors
  } = useForm<SignInFormInputs>({ defaultValues });

  const {
    mutation: { mutate: getPinCode, isSuccess: isGetPinCodeSuccess },
    repeatSeconds,
    isRepeat
  } = useGetPinCodeMutation();

  const { mutate: confirmPinCode } = useConfirmPinCodeMutation();

  const setServerError = (options?: ErrorOption) => {
    setError("root.serverError", {
      ...options,
      message: options?.message || t("common.errors.unknown_error")
    });
  };

  const handleSubmitValid: SubmitHandler<SignInFormInputs> = ({ phone, pinCode }) => {
    confirmPinCode(
      {
        phone: filterStringNumbers(phone),
        pinCode
      },
      {
        onSuccess: ({ data: { error } }) => {
          if (!error) {
            return;
          }

          switch (error.code) {
            case "auth_pincode_incorrect":
              setError("pinCode", { message: t("auth.errors.auth_pincode_incorrect") });
              break;

            case "auth_error_1":
              setServerError({
                type: error.code
              });
              break;

            case "auth_error_3":
              setServerError({
                type: error.code,
                message: t("auth.errors.auth_error_3")
              });
              break;

            case "auth_error_5":
              setServerError({
                type: error.code,
                message: t("auth.errors.auth_error_5")
              });
              break;

            default:
              setServerError({
                type: error.code,
                message: error?.message
              });
              break;
          }
        },
        onError: (error) => setServerError({ message: error?.message })
      }
    );
  };

  const handleSubmitForm = handleSubmit(handleSubmitValid);

  const handleConfirmClick: MouseEventHandler<HTMLButtonElement> = async () => {
    const phoneIsValid = await trigger("phone");

    if (!phoneIsValid) {
      return;
    }

    const phone = getValues().phone;

    getPinCode(
      { phone: filterStringNumbers(phone) },
      {
        onError: () => setServerError()
      }
    );
  };

  useEffect(() => {
    if (isGetPinCodeSuccess) {
      setFocus("pinCode");
    }
  }, [isGetPinCodeSuccess, setFocus]);

  const handlePinCodeChange: RegisterOptions["onChange"] = (e) => {
    if (e?.target?.value?.length === 4) {
      handleSubmitForm();
    }
  };

  const serverError = errors.root?.serverError;

  return (
    <Paper
      sx={{ paddingX: "52px", pt: "45px", pb: "41px", maxWidth: "450px" }}
      component='form'
      onSubmit={handleSubmitForm}
    >
      <Typography variant='h2' component='h1'>
        {t("auth.sign_in_page")}
      </Typography>

      {serverError && <ServerErrorAlert sx={{ mt: 2 }} error={serverError} />}

      <PhoneField
        sx={{ mt: "25px" }}
        fullWidth
        name='phone'
        control={control}
        clearErrors={clearErrors}
        trigger={trigger}
      />

      <ConfirmPhoneButton
        fullWidth
        sx={{ mt: "16px" }}
        disabled={checkConfirmPhoneDisabled({
          repeatSeconds,
          phone: watch("phone"),
          invalid: "phone" in errors
        })}
        repeatSeconds={repeatSeconds}
        isRepeat={isRepeat}
        onClick={handleConfirmClick}
      />

      <PinCodeField
        fullWidth
        sx={{ mt: "48px" }}
        name='pinCode'
        control={control}
        disabled={!isGetPinCodeSuccess}
        rules={{ onChange: handlePinCodeChange }}
      />

      <Box
        sx={{
          mt: "41px",
          display: "flex",
          gap: "16px",
          justifyContent: "center"
        }}
      >
        <MuiLink component={Link} to={Paths.SignUp}>
          {t("auth.sign_up")}
        </MuiLink>

        <MuiLink component={Link} to={Paths.Feedback}>
          {t("common.feedback")}
        </MuiLink>
      </Box>

      <Button
        type='submit'
        variant='contained'
        fullWidth
        sx={{ mt: "23px" }}
        disabled={!isGetPinCodeSuccess}
      >
        {t("auth.sign_in")}
      </Button>

      <Typography sx={{ mt: 1, textAlign: "center" }}>
        {`${t("auth.sign_in_agreement.part_1")} `}
        <MuiLink href={window.PRIVACY_POLICY_URL ?? "#"} target='_blank' rel='noopener noreferrer'>
          {t("auth.sign_in_agreement.part_2")}
        </MuiLink>
        {` ${t("auth.sign_in_agreement.part_3")} `}
        <MuiLink href={window.PUBLIC_OFFER_URL ?? "#"} target='_blank' rel='noopener noreferrer'>
          {t("auth.sign_in_agreement.part_4")}
        </MuiLink>
      </Typography>
    </Paper>
  );
};
