import { Alert, Button, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { LegalCabinetGatewayV1SendFeedbackRequest } from "api/generated";
import { EmailField } from "components/form";
import { useNavigationContext } from "hooks/useNavigationContext";
import { setServerError } from "utils/setServerError";

import { useFeedbackSendMutation } from "../hooks";
import { FeedbackFormInputs } from "../types";

import { FeedbackFilesContainer } from "./FeedbackFilesContainer";
import { FeedbackSuccessDialog } from "./FeedbackSuccessDialog";
import { FeedbackTextField } from "./FeedbackTextField";
import { TopicSelect } from "./TopicSelect";

export const FeedbackForm = () => {
  const { t } = useTranslation();

  const {
    control,
    trigger,
    getValues,
    setValue,
    setError,
    formState: { isValid, errors },
    watch,
    handleSubmit
  } = useForm<FeedbackFormInputs>({
    defaultValues: {
      topic: "",
      email: "",
      text: "",
      files: []
    }
  });

  const { mutate: sendFeedback } = useFeedbackSendMutation();

  const [successDialogOpen, setSuccessDialogOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { previousPathname } = useNavigationContext();

  const handleSubmitValid: SubmitHandler<FeedbackFormInputs> = (data) => {
    const fileUuids: string[] = data.files
      .map(({ uuid }) => uuid)
      .filter((uuid) => typeof uuid === "string") as string[];

    const requestBody: LegalCabinetGatewayV1SendFeedbackRequest = {
      fileUuids,
      text: data.text,
      topicUuid: data.topic
    };

    if (data.email) {
      requestBody.email = data.email;
    }

    sendFeedback(requestBody, {
      onSuccess: ({ data: { error } }) => {
        if (error) {
          setServerError(setError, t, {
            message: error?.message
          });
        } else {
          setSuccessDialogOpen(true);
        }
      },
      onError: (error) =>
        setServerError(setError, t, {
          message: error?.message
        })
    });
  };

  const handleCloseSuccess = () => {
    if (previousPathname !== location.pathname) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  const serverErrorMessage = errors.root?.serverError?.message;

  return (
    <Paper
      component='form'
      sx={{ paddingY: "56px", paddingX: "41px" }}
      onSubmit={handleSubmit(handleSubmitValid)}
    >
      <Typography variant='h1'>{t("feedback.title")}</Typography>

      {serverErrorMessage && (
        <Alert severity='error' sx={{ mt: 2 }}>
          {serverErrorMessage}
        </Alert>
      )}

      <EmailField
        sx={{ mt: 2 }}
        control={control}
        name='email'
        trigger={trigger}
        fullWidth
        label={t("common.labels.email")}
        placeholder={t("feedback.placeholders.email")}
        forcePlaceholder
        multiline
      />

      <TopicSelect control={control} fullWidth sx={{ mt: 2 }} setValue={setValue} watch={watch} />

      <FeedbackTextField control={control} name='text' fullWidth sx={{ mt: 2 }} />

      <FeedbackFilesContainer control={control} getValues={getValues} setError={setError} />

      <Button variant='contained' disabled={!isValid} sx={{ mt: 2 }} type='submit'>
        {t("common.send")}
      </Button>

      <FeedbackSuccessDialog open={successDialogOpen} onClose={handleCloseSuccess} />
    </Paper>
  );
};
