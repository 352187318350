import { FC } from "react";
import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";

import { queryClient } from "api";
import { Layout } from "components/layout";
import { NavigationContextProvider } from "context";
import { useAuthContext, SignIn, SignUp } from "features/auth";
import { Balance } from "features/balance";
import { Details } from "features/company-details";
import { Employees } from "features/employees";
import { Feedback } from "features/feedback";
import { History } from "features/history";
import { Vehicles } from "features/vehicles";
import { prefetchConfigQuery } from "services/configQuery";

import { Paths } from "./paths";
import { SimpleRoute } from "./SimpleRoute";

const PrivateRoutes: FC = () => {
  const { isSignedIn } = useAuthContext();
  return isSignedIn ? <Outlet /> : <Navigate to={Paths.SignIn} />;
};

const PublicRoutes: FC = () => {
  const { isSignedIn } = useAuthContext();
  return isSignedIn ? <Navigate to={Paths.Vehicles} /> : <Outlet />;
};

const Root = () => {
  return (
    <NavigationContextProvider>
      <Layout />
    </NavigationContextProvider>
  );
};

export const router = createBrowserRouter([
  {
    loader: () => {
      prefetchConfigQuery(queryClient);
      return null;
    },
    element: <Root />,
    children: [
      {
        index: true,
        element: <Navigate to={Paths.Vehicles} />
      },
      {
        element: <PublicRoutes />,
        children: [
          {
            path: Paths.SignIn,
            element: <SignIn />
          },
          {
            path: Paths.SignUp,
            element: <SignUp />
          }
        ]
      },
      {
        element: <PrivateRoutes />,
        children: [
          {
            path: Paths.Balance,
            element: <Balance />
          },
          {
            path: Paths.Vehicles,
            element: <Vehicles />
          },
          {
            path: Paths.Employees,
            element: <Employees />
          },
          {
            path: Paths.CompanyDetails,
            element: <Details />
          },
          {
            path: Paths.Fines,
            element: <SimpleRoute title='Fines' />
          },
          {
            path: Paths.History,
            element: <History />
          }
        ]
      },
      {
        path: Paths.Feedback,
        element: <Feedback />
      }
    ]
  }
]);
