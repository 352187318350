import { Alert, Link as MuiLink, SxProps } from "@mui/material";
import { Message } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Paths } from "routes/paths";

interface ServerErrorAlertProps {
  error: {
    type?: string | number;
    message?: Message;
  };
  sx?: SxProps;
}

export const ServerErrorAlert = ({ sx, error }: ServerErrorAlertProps) => {
  const { t } = useTranslation();

  return (
    <Alert severity='error' sx={sx}>
      {error.type === "auth_error_1" ? (
        <>
          {t("auth.errors.auth_error_1.text") + " "}
          <MuiLink component={Link} to={Paths.SignUp} color='error'>
            {t("auth.errors.auth_error_1.link")}
          </MuiLink>
        </>
      ) : (
        error.message
      )}
    </Alert>
  );
};
