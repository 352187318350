import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { requests } from "api";
import { LegalCabinetGatewayV1GetPinCodeRequest } from "api/generated";
import { useCountDown } from "hooks";

const getPinCode = async (body: LegalCabinetGatewayV1GetPinCodeRequest) => {
  const response = await requests.legalCabinetGatewayGetPinCode(body);

  if (response.data.error) {
    throw new Error(response.data.error.code || "Unknown error");
  }

  return response;
};

export const useGetPinCodeMutation = () => {
  const {
    start: startRepeatCountDown,
    reset: resetRepeatCountDown,
    seconds: repeatSeconds
  } = useCountDown(120);

  const [isRepeat, setIsRepeat] = useState<boolean>(false);

  const mutation = useMutation({
    mutationFn: getPinCode,
    onSuccess: () => {
      startRepeatCountDown();
      setIsRepeat(true);
    }
  });

  return {
    mutation,
    resetRepeatCountDown,
    repeatSeconds,
    isRepeat
  };
};
